<template>
  <div>
    <el-divider content-position="left">标签分发单</el-divider>
    <el-row>
      <el-form-item style="margin-left: 0px;margin-right: 12px;">
        <div class="demo-date-picker">
          <div class="block">
            <span class="demonstration">日期：</span>
            <el-date-picker size="mini"
              clearable
              value-format="YYYY-MM-DD"
              v-model="valueDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间">
            </el-date-picker>
          </div>
        </div>
      </el-form-item>
      <el-form-item style="margin-left: 10px;margin-right: 12px;">
        <el-input  @keyup.enter="handleFilter" size="mini" placeholder="输入订单批次查询" v-model="listQuery.condition.keyword" clearable></el-input>
      </el-form-item>
      <el-form-item style="margin-left: 10px;margin-right: 12px;">
        <el-button class="filter-item" size="mini" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
      </el-form-item>
    </el-row>
    <el-row style="margin-bottom: 20px">
      <el-button type="success" v-waves icon="el-icon-check" size="mini" @click="distributeSave">生成分发单</el-button>
    </el-row>
    <el-table :data="orderList" style="width: 100%" border id="labelTable" el-table-column="selection" @selection-change="selectOrder">
      <el-table-column type="selection" width="55" />
      <el-table-column min-width="150px" label="订单批次" property="orderBatch" align="center">
        <template #default="scope">
          <span>{{scope.row.orderBatch}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="180px" label="接收时间" align="center" property="receiptTime">
          <template  #default="scope">
            <span>{{scope.row.receiptTime}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="140px" label="防伪标签（套）" property="securityNumber" align="center">
          <template #default="scope">
            <span>{{scope.row.securityNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="140px" label="采购条码数（枚）" property="barcodeNumber" align="center">
          <template #default="scope">
            <span>{{scope.row.barcodeNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="180px" label="标签供应商" property="supplier.name" align="center">
          <template #default="scope">
            <span>{{scope.row.supplier.name}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="120px" label="厂家数量" property="manufacturerNum" align="center">
          <template #default="scope">
            <span>{{scope.row.manufacturerNum}}</span>
          </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="distributeShow" title="生成标签分发单" width="450px">
        <el-form ref="distribution" :model="distribution" label-position="left">
            <el-form-item label="选中订单数（单）:" prop="logisticsNumber">
               <span style="color:red">{{selectOrderList.length}}</span>
            </el-form-item>
            <el-form-item label="系统将基于已选中的订单，分别为您生成相应的“标签分发单”">
            </el-form-item>
            <el-form-item>
                <el-button size="mini" @click="onCancelButtonClick">取消</el-button>
                <el-button size="mini" type="primary" @click="onSubmitButtonClick(false)" :loading="distribution.loading">{{distribution.loading ? '提交中' : '确定'}}</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
  </div>
</template>

<script>
import inDistributeListApi from '@/apis/distribute/inDistributeList'
export default {
  props: {},
  data() {
    return {
      valueDate: undefined,
      selectOrderList: [],
      orderList: [],
      distributeShow: false,
      distribution:{},
      listQuery: {
        condition:{
          keyword: null,
          startReceiptTime: undefined,
          endReceiptTime: undefined
        }
      }
    }
  },
  created() {
    this.getAllDistributeList()
  },
  mounted(){
  },
  methods:{
    getAllDistributeList(){
      inDistributeListApi.allDistributeList(this.listQuery.condition).then(resp =>{
        if(resp.data){
          this.orderList = resp.data
        }
      })
    },
    onCancelButtonClick(){
      this.distributeShow = false
    },
    onSubmitButtonClick(){
      var distributeList = this.selectOrderList.map(info=>{
        return info.id
      })
      inDistributeListApi.distributeSave(distributeList).then(resp=>{
        if(resp.data){
          this.$message.success("成功")
          this.distributeShow = false
          //this.getAllDistributeList()
          this.$emit('close',false)
        }
      })
    },
    distributeSave(){
      if(this.selectOrderList && this.selectOrderList.length>0){
        this.distributeShow = true
      }else{
        this.$message.warning("请勾选订单")
      }
    },
    selectOrder(selection){
      this.selectOrderList = selection
    },
    /**
     * 搜索
     */
    handleFilter () {
      if(this.valueDate){
        this.listQuery.condition.startReceiptTime = this.valueDate[0] + " 00:00:00"
        this.listQuery.condition.endReceiptTime = this.valueDate[1] + " 23:59:59"
      }else{
        this.listQuery.condition.startReceiptTime = undefined
        this.listQuery.condition.endReceiptTime = undefined
      }
      this.getAllDistributeList()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>