import api from '../api'
/**
 * 分发中列表
 */
export class inDistributeListApi extends api {
    allDistributeList(condition){
        return this.request({
          url: `${this.servicePath}/${this.modulePath}/allDistributeList`,
          method: 'post',
          data: condition
        })
    }
    distributeSave(data){
      return this.request({
        url: `${this.servicePath}/${this.modulePath}/distributeSave`,
        method: 'post',
        data
      })
    }
}

export default new inDistributeListApi('isDistributing')